<template>
  <layout-login>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Login</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="login"
            prepend-icon="fa-sign-in-alt"
            name="login"
            label="E-mail"
            type="text"
            required
            v-validate="'required'"
            :error-messages="errors.collect('login')"
            :error="hasErrors"
          ></v-text-field>
          <v-text-field
            v-model="password"
            prepend-icon="fa-key"
            name="password"
            label="Password"
            type="password"
            required
            v-validate="'required'"
            :error-messages="errors.collect('password')"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" outlined @click="$router.push({ name: 'register' })"
          >Signup</v-btn
        >
        <v-btn color="primary" @click="dologin" :loading="loading">Login</v-btn>
      </v-card-actions>
    </v-card>
    <v-alert v-if="hasErrors" :value="true" color="error" icon="fa-error">
      {{ lastError }}
    </v-alert>
  </layout-login>
</template>
<script>
import LayoutLogin from "@/layouts/Login.vue";
import LOGIN_MUTATION from "@/graphql/mutation/LoginMutation.gql";
import { onLogin } from "@/vue-apollo";
import { errorsMap } from "@/errorsMap";

export default {
  $_veeValidate: {
    validator: "new",
  },
  data: () => {
    return {
      lastError: "Unknown error",
      hasErrors: false,
      loading: false,
      login: null,
      password: null,
      token: null,
      user: null,
    };
  },
  filters: {
    mapError(code) {
      return errorsMap[code];
    },
  },
  components: {
    LayoutLogin,
  },
  methods: {
    async dologin() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      const variables = {
        email: this.login,
        password: this.password,
      };

      this.loading = true;

      this.$apollo
        .mutate({
          mutation: LOGIN_MUTATION,
          variables,
          update: async (store, { data, errors }) => {
            if (errors) {
              return;
            }
            const apolloClient = this.$apollo.provider.defaultClient;
            await onLogin(apolloClient, data.loginUser.token);
            this.$router.push({ path: "/" });
          },
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.hasErrors = true;
          this.loading = false;
          this.lastError = error;
        });
    },
  },
};
</script>
